<template>
    <div>
        <v-row dense class="mb-3">
            <v-col cols="4">
                <select-pos-model v-model="idPos" :label="$t('labels.pos')"
                    @change="onPosChange"></select-pos-model>
            </v-col>
            <v-col cols="8">
                <input-qr-scan-model ref="inputQr" v-model="code" :label="label" @keyupEnter="inputScan"
                    :disabled="!idPos"></input-qr-scan-model>
            </v-col>
        </v-row>

        <div>
            <v-simple-table fixed-header height="calc(100vh - 145px)" class="table-padding-2-no-top">
                <thead class="v-data-table-header">
                    <tr>
                        <th role="columnheader" class="text-center" style="min-width: 100px">{{ $t('labels.code') }}</th>
                        <th role="columnheader" class="text-center" style="min-width: 100px">
                            {{ $t('labels.basket_code_or_location') }}
                        </th>
                        <!-- <th role="columnheader" class="text-center" style="min-width: 100px">{{ $t('labels.sku') }}</th> -->
                        <th role="columnheader" class="text-center">{{ $t('labels.quantity') }}</th>
                        <th style="width: 96px;" role="columnheader" class="text-center">
                            <v-btn x-small color="primary" @click="addItem" :disabled="isDisabledBtnAdd">
                                {{ $t('labels.add') }}
                            </v-btn>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="act in actions" :key="`act_${act.id}`" class="text-center">
                        <td>
                            {{ act.name }}
                            <span v-if="act.code">({{ act.code }})</span>
                        </td>
                        <td>{{ act.basket_code || act.cell_id }}</td>
                        <!-- <td>{{ act.sku }}</td> -->
                        <td>
                            <span class="cursor-pointer primary--text text-decoration-underline"
                                @click="showDetailUids(act)">
                                {{ act.uids.length > 0 ? act.uids.length : '' }}
                            </span>
                        </td>
                        <td>
                            <v-btn v-if="act.uids.length > 0" x-small color="success" block @click="finishAction(act)">
                                {{ $t('labels.finish') }}
                            </v-btn>
                        </td>
                    </tr>
                </tbody>
            </v-simple-table>
        </div>


        <v-dialog v-model="detailUidDialog" max-width="480px">
            <v-card v-if="detailUidDialog">
                <v-card-title class="text-h6">
                    {{ detail.code }} - {{ detail.basket_code || detail.cell_id }}
                    <v-spacer></v-spacer>
                    <v-btn small color="red darken-1" text @click="hideDetailUids">
                        {{ $t('labels.close') }}
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <v-row dense>
                        <v-col cols="4" v-for="sku_uid in detail.sku_uids" :key="sku_uid">{{ sku_uid }}</v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { httpClient } from "@/libs/http";
import { GOODS_UID_ACTION_TYPE_PREPAIR } from "@/libs/const";

export default {
    name: "SetPosition",
    components: {
        // ImageViewer: () => import('@/components/common/ImageViewer'),
    },
    data: () => ({
        idPos: null,
        code: null,
        step: 1,
        isLoading: false,
        basket: {},
        action: {},
        item: {},
        waitAdd: false,
        actions: [],
        detailUidDialog: false,
        detail: {}
    }),
    watch: {},
    computed: {
        label() {
            if (this.waitAdd) {
                switch (this.step) {
                    case 1:
                        return this.$t('labels.code_action')
                    case 2:
                        return this.$t('labels.basket_code_or_location')
                    case 3:
                        return this.$t('labels.uid')
                    case 4:
                        return this.$t('labels.basket_code_or_location')
                    default:
                        return '--- ERROR ---'
                }
            } else {
                switch (this.step) {
                    case 3:
                        return this.$t('labels.uid')
                    case 4:
                        return this.$t('labels.basket_code_or_location')
                    default:
                        return '--- ERROR ---'
                }
            }
        },
        isDisabledBtnAdd() {
            return !!(this.actions.find(act => !act.code || /* !act.sku || */ (!act.basket_code && !act.cell_id)))
        }
    },
    mounted() {
        this.getItemsPreparing()
    },
    methods: {
        onPosChange() {
            this.focusInputQr()
            this.resetValue()
        },
        focusInputQr() {
            setTimeout(() => {
                this.$refs.inputQr.focusInput()
            }, 200)
        },
        resetValue() {
            this.step = 1
            this.actions = []
            this.item = {}
            this.addItem()
        },
        addItem() {
            const newAction = {
                id: this.generateRandomString(5),
                code: null,
                name: null,
                type: null,
                scan_location: null,
                scan_basket: null,
                sku: null,
                uids: [],
                sku_uids: [],
                basket_code: null,
                cell_id: null,
                id_pos: this.idPos,
            }
            this.actions.push(newAction)
            this.waitAdd = true
            this.step = 1
            this.focusInputQr()
        },
        cancelAddItem() {
            this.waitAdd = false
        },

        showDetailUids(item) {
            this.detail = { ...item }
            this.detailUidDialog = true
        },

        hideDetailUids() {
            this.detail = {}
            this.detailUidDialog = false
        },

        getItemsPreparing() {
            httpClient.post('/get-items-preparing').then(({ data }) => {
                if (!data || data.length === 0) {
                    this.resetValue()
                } else {
                    this.actions = [...data].map(item => ({
                        id: this.generateRandomString(5),
                        ...item
                    }))
                    this.idPos = data[0] && data[0].id_pos
                    this.step = 3
                }
            })
        },

        async finishAction(action) {
            if (!confirm(this.$t('messages.are_you_sure_confirm_preparing'))) {
                return false
            }
            if (this.isLoading) {
                this.$vToastify.warning(this.$t('messages.loading'));
                return false
            }
            this.isLoading = true

            try {
                await httpClient.post('/finish-action', {
                    ...action,
                    id_pos: this.idPos
                })

                this.code = null
                this.isLoading = false
                this.$root.$emit('playSuccessAudio')
                this.getItemsPreparing()
            } catch (e) {
                const errMsg = e.response && e.response.data && e.response.data.error && e.response.data.error.message || null
                this.$vToastify.error(errMsg)
                this.code = null
                this.isLoading = false
                this.$root.$emit('playErrorAudio')
            }
        },


        inputScan() {
            if (!this.code) {
                return false
            }

            if (this.waitAdd) {
                switch (this.step) {
                    case 1:
                        return this.scanAction()
                    case 2:
                        return this.scanBasketOrLocation()
                    case 3:
                        return this.scanUid()
                    case 4:
                        return this.confirmItemAction()
                    default:
                        this.$vToastify.error('Wrong!');
                }
            } else {
                switch (this.step) {
                    case 3:
                        return this.scanUid()
                    case 4:
                        return this.confirmItemAction()
                    default:
                        this.$vToastify.error('Wrong!');
                }
            }
        },

        async scanAction() {
            if (this.isLoading) {
                this.$vToastify.warning(this.$t('messages.loading'));
                return false
            }
            this.isLoading = true

            const key = this.actions.length - 1
            const actions = [...this.actions]

            try {
                const { data } = await httpClient.post('/get-action', {
                    code: this.code,
                    id_pos: this.idPos
                })
                actions[key].name = data.name
                actions[key].code = data.code
                this.actions = [...actions]

                this.step = 2
                this.code = null
                this.isLoading = false
                this.$root.$emit('playSuccessAudio')
            } catch (e) {
                const errMsg = e.response && e.response.data && e.response.data.error && e.response.data.error.message || null
                this.$vToastify.error(errMsg)
                this.code = null
                this.isLoading = false
                this.$root.$emit('playErrorAudio')
            }
        },

        async scanBasketOrLocation() {
            const checkDuplicate = this.actions.find(act => act.basket_code == this.code || act.cell_id == this.code)
            if (checkDuplicate) {
                this.$vToastify.error(`Sọt | Vị trí ${this.code} đã gán với mã ${checkDuplicate.code}`)
                this.code = null
                this.$root.$emit('playErrorAudio')
                return false
            }

            if (this.isLoading) {
                this.$vToastify.warning(this.$t('messages.loading'));
                return false
            }
            this.isLoading = true

            const key = this.actions.length - 1
            const actions = [...this.actions]

            try {
                const { data } = await httpClient.post('/get-basket-or-location-for-action', {
                    code: this.code,
                    action_code: actions[key].code,
                    id_pos: this.idPos
                })

                const basket = data.basket || {}
                const location = data.location || {}

                actions[key].basket_code = basket.code
                actions[key].cell_id = location.cell_id
                this.actions = [...actions]

                this.step = 3
                this.code = null
                this.isLoading = false
                this.$root.$emit('playSuccessAudio')
            } catch (e) {
                const errMsg = e.response && e.response.data && e.response.data.error && e.response.data.error.message || null
                this.$vToastify.error(errMsg)
                this.code = null
                this.isLoading = false
                this.$root.$emit('playErrorAudio')
            }
        },

        async scanUid() {


            if (this.isLoading) {
                this.$vToastify.warning(this.$t('messages.loading'));
                return false
            }
            this.isLoading = true

            try {
                const { data } = await httpClient.post('/get-item', {
                    code: this.code,
                    id_pos: this.idPos,
                    action_type: GOODS_UID_ACTION_TYPE_PREPAIR,
                })

                this.code = null
                this.isLoading = false

                if (!data.available) {
                    this.$vToastify.error(this.$t('messages.uid_unavailable'))
                    this.$root.$emit('playErrorAudio')
                    return false
                }

                this.item = { ...data }
                this.step = 4
                this.$root.$emit('playSuccessAudio')

            } catch (e) {
                const errMsg = e.response && e.response.data && e.response.data.error && e.response.data.error.message || null
                this.$vToastify.error(errMsg)
                this.code = null
                this.isLoading = false
                this.$root.$emit('playErrorAudio')
            }
        },

        async confirmItemAction() {
            const actions = [...this.actions]
            const key = actions.findIndex(act => act.basket_code == this.code || act.cell_id == this.code)
            const action = actions[key]

            if (!action || (this.code != action.basket_code && this.code != action.cell_id)) {
                this.code = null
                this.$vToastify.error(`Sọt | Vị trí không đúng`)
                this.$root.$emit('playErrorAudio')
                return false
            }

            if (this.isLoading) {
                this.$vToastify.warning(this.$t('messages.loading'));
                return false
            }
            this.isLoading = true

            try {
                await httpClient.post('/set-item-to-action', {
                    ...actions[key],
                    sku_uid: this.item.sku_uid,
                    id_pos: this.idPos
                })

                this.code = null
                this.isLoading = false
                this.step = 3

                actions[key].uids.push(this.item.id)
                actions[key].sku_uids.push(this.item.sku_uid)
                this.actions = [...actions]
                this.item = {}

                this.$root.$emit('playSuccessAudio')
                this.cancelAddItem()

            } catch (e) {
                const errMsg = e.response && e.response.data && e.response.data.error && e.response.data.error.message || null
                this.$vToastify.error(errMsg)
                this.code = null
                this.isLoading = false
                this.$root.$emit('playErrorAudio')
            }
        },
    }
}
</script>

<style scoped></style>